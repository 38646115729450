.TableRow {
  opacity: 0;
  &-Enter {
    opacity: 1;
    transition: opacity 0.1s;
  }

  &-Hover {
    background-color: transparent;
    position: relative;
    &:hover {
      transition: background-color 0.1s;
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}
