/* Sizing */
$header-height: 80px;
$menu-width: 320px;

/* Colors */
$white: white;
$extraLight: rgb(249, 249, 251);
$extraLightDisabled: rgb(251, 251, 253);
$light: rgb(225, 225, 225);
$grey: rgb(200, 208, 214);
$greyText: #8e9fa9;
$medium: #222f47;
$dark: rgb(50, 50, 50);

$lightBlue: rgba(0, 175, 255, 1);
$lightBlueDisabled: rgb(164, 219, 252);
$blue: rgba(0, 140, 215, 1);
$darkBlue: rgba(30, 100, 180, 1);

$background: linear-gradient(90deg, $darkBlue 0%, $blue 100%);
$menu: #222f47;
$disabled: rgb(95, 170, 215);
$disabledGrey: rgb(235, 235, 235);
$dialogBackground: rgba(52, 59, 69, 0.9);

$text-xxs: 0.65rem;
$text-xs: 0.75rem;
$text-sm: 0.85rem;
$text-md: 0.9rem;
$text-base: 1rem;
$text-lg: 1.25rem;
