.Organization {
  margin: 0 auto;
  padding: 0 2rem;

  @media screen and (min-width: 1200px) {
    max-width: 1024px;
    padding: 0;
  }

  @media screen and (min-width: 1400px) {
    max-width: 1200px;
    padding: 0;
  }

  @media screen and (min-width: 1440px) {
    max-width: 1400px;
    padding: 0;
  }
}
