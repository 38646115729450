// Variables:
@import '../../../../../styles/variables';

.Droppable-Devices-Scene,
.Droppable-Pillars-Scene {
  position: fixed;
  top: $header-height;
  left: 0;

  height: calc(100vh - #{$header-height});
  width: calc(100% - 360px);

  max-height: calc(100vh - #{$header-height});
  max-width: calc(100% - 360px);

  z-index: 5;
  padding: calc(50vh - 200px) calc(50vw - 300px);

  display: flex;

  transition: all 0.25s ease-in-out;

  pointer-events: none;
}

.Subcategory {
  &-Accordion {
    margin-top: 0.75rem;
    border-bottom: 1px solid $light;
    padding-bottom: 0.75rem;
    font-size: 0.85rem;
  }
  &-Panel {
    margin-top: 0.5rem;
  }
}
