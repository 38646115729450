// Variables:
@import '../../../styles/variables';

.AEditor-Menu {
  right: 0;
  &-Exited {
    opacity: 1;
    right: -$menu-width;
    width: $menu-width;
    transition: opacity 0.25s, right 0.25s;
  }

  &-Mounted {
    opacity: 1;
    right: 0;
    width: $menu-width;
    transition: opacity 0.5s, right 0.5s;

    &-Expanded {
      opacity: 1;
      right: 10px;
      width: calc(100% - 10px);
      transition: opacity 1.25s, right 1.25s;
    }
  }
}
