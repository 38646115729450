// Variables:
@import '../../styles/_variables.scss';
$margin-item: 1rem;

// ===================================================================
.Header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;

  color: $medium;

  display: flex;
  flex-direction: column;

  /* Navigation */
  &-Navigation {
    width: 100%;
    height: $header-height;

    display: flex;
    justify-content: space-between;

    box-sizing: border-box;

    &-Collection {
      flex: 1;
      display: flex;
      align-items: center;
    }

    &-Separation {
      height: 60%;
      width: 2px;
      margin: 0 26px 0 0;
    }

    &-EdgeItem {
      width: 94px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-Item {
      display: flex;
      align-items: center;
      margin: 0;

      color: $medium;
      font-weight: bold;

      &-Content {
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;

        cursor: pointer;

        &-Heading {
          color: $greyText;
          font-family: HelveticaNeueLT;
          font-size: 13px;
          letter-spacing: 0.5px;
          line-height: 16px;
          margin-bottom: 0.75rem;
        }

        &-Text {
          font-size: 9px;
          color: $medium;
          letter-spacing: 0.7px;
          line-height: 12px;
          text-transform: uppercase;
          font-weight: bold;

          display: flex;
          align-items: center;
        }
      }
    }

    /* Mode Selection Header Items */
    &-ItemInactive {
      background: transparent;
      border: none;
      text-align: center;
      color: $medium;
      text-transform: uppercase;
      font-size: 0.65rem;
      cursor: pointer;
      margin-right: 2rem;
    }

    &-ItemActive {
      background: transparent;
      border: none;
      text-align: center;
      color: $lightBlue;
      text-transform: uppercase;
      font-size: 0.65rem;
      cursor: pointer;
      margin-right: 2rem;
    }

    &-ItemActive:hover,
    &-ItemInactive:hover {
      cursor: pointer;
    }
  }

  /* Content */
  &-Content {
    width: 100%;

    background: #f4f3f4;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-Expanded {
      height: calc(100vh - #{$header-height});
      opacity: 1;
      transition: height 0.75s ease-in, opacity 0.75s ease-in;
    }

    &-Collapsed {
      height: 0px;
      opacity: 0;
      transition: height 0.5s linear, opacity 0.5s linear;
      overflow: hidden;
    }

    /* Main */
    &-Main {
      width: 100%;

      background: #f4f3f4;

      overflow-x: none;
      overflow-y: auto;

      margin-bottom: 80px;

      &-Static {
        margin: 0 6rem;
      }

      &-Break {
        display: flex;
        align-items: center;

        margin: 1rem auto 3rem;
        padding: 1.5rem 3rem;

        background: $white;

        span {
          font-size: 1.25rem;
          font-weight: bold;
          color: $medium;
        }
      }

      &-DeviceTable {
        width: 100%;
        max-width: 100%;

        margin: 1rem auto 3rem;
        padding: 0 6rem;
        table {
          width: 100%;
          table-layout: fixed;

          .Row {
            &-L {
              width: 7.5%;
            }
            &-Empty {
              width: 7.55%;
            }
            &-Amount {
              width: 12.5%;
            }
            &-Code {
              width: 15%;
            }
            &-Description {
              width: 45%;
              text-align: left;
              padding-left: 2.5rem;
            }
            &-Price {
              width: 12.5%;
              padding-right: 1.5rem;
            }
          }

          th {
            background: #c8d0d5;
            color: white;
            text-transform: uppercase;
            font-size: 0.85rem;
            padding: 1rem;
            letter-spacing: 0.085em;
          }

          .Content-Row {
            td {
              padding: 0.5rem 1rem 0.75rem 1.5rem;
            }
            &-Bg {
              background: white;
              td {
                padding: 0.5rem 1rem 0.75rem 1.5rem;
              }
            }
          }
        }
      }
    }

    /* Footer */
    &-Footer {
      height: $header-height;
      width: 100%;
      position: fixed;
      left: 0;
      bottom: 0;
      background: $white;

      display: flex;
      justify-content: space-around;
      align-items: center;

      &-Collection {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      &-Collapsed {
        height: 0px;
        opacity: 0;
        transition: height 0.5s linear, opacity 0.5s linear;
        overflow: hidden;
      }
      &-Item {
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;

        font-weight: bold;
        color: $greyText;
        font-family: HelveticaNeueLT;
        font-size: 13px;
        letter-spacing: 0.5px;
        line-height: 16px;

        &-Sub {
          margin-top: 0.75rem;
          font-size: 9px;
          color: $medium;
          letter-spacing: 0.7px;
          line-height: 12px;
          text-transform: uppercase;
          font-weight: bold;
          font-family: Arial, Helvetica, sans-serif;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.Faded-Out {
  opacity: 0;
}
