// Variablesl:
@import '../../styles/variables';

.View {
  position: relative;

  display: flex;
  width: 100%;
  height: 100%;

  background: $light;

  &-Scene {
    position: relative;
    height: 100%;

    &-LoadingIndicator {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      z-index: 9999;
    }
  }

  &-Logo {
    position: absolute;
    top: 0.95rem;
    right: 1.33rem;
    height: 50px;
    width: 50px;
    z-index: 100;
  }

  &-Menu {
    position: absolute;
    height: calc(100% - 20px);
    top: 10px;
    z-index: 20;

    max-width: calc(100vw - 20px);

    background: transparent;

    display: flex;
  }
}

#inspector-host,
#scene-explorer-host {
  z-index: 100;
}
