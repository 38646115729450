// Variables:
@import '../../styles/variables';

.ProfileDialog-Background {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;

  z-index: 801;

  background: $dialogBackground;
}

.ProfileDialog-Content {
  position: fixed;

  width: 460px;
  height: 520px;

  z-index: 802;

  background: $white;

  &-Icon {
    position: absolute;

    top: 1rem;
    right: 1rem;
  }

  &-Main {
    margin: 0 auto;
    padding: 1rem 2rem;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
