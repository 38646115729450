// Variables:
@import './fonts';
@import './spacing';
@import './variables';

#root {
  height: 100%;
  --app-height: 100%;
  width: 100%;
  overflow: hidden;

  scrollbar-width: thin !important;
  scrollbar-color: $grey transparent !important;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: $light;
    border-radius: 6px;
    border: 1px solid white;
  }
}

html {
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  height: var(--app-height);
}

body {
  position: relative;
  background: $background;
  box-sizing: border-box;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-family: HelveticaNeueLT;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  height: var(--app-height);
}

.disable-dbl-tap-zoom {
  touch-action: manipulation;
}

button {
  cursor: pointer;
}

button:focus {
  outline: none;
}

.bold {
  font-weight: bold;
}

.text-xxs {
  font-size: $text-xxs;
}

.text-xs {
  font-size: $text-xs;
}

.text-sm {
  font-size: $text-sm;
}

.text-md {
  font-size: $text-md;
}

.text-base {
  font-size: $text-base;
}

.text-lg {
  font-size: $text-lg;
}

.flex {
  display: flex;
}

.w-100 {
  width: 100%;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.text-center {
  text-align: center;
}

.h-100 {
  height: 100%;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.uppercase {
  text-transform: uppercase;
}

.pointer:hover {
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.Custom-Scroll {
  overflow-y: auto;
  overflow-x: hidden;
}

.disabled {
  color: $disabledGrey;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
