.AMenu {
  opacity: 0;
  transition: opacity 0.2s;

  &-Mounted {
    opacity: 1;
    height: 100vh;
    transition: opacity 0.5s;
  }
}
