.Editor-Menu-Item {
  opacity: 0;
  top: 0;
  left: -100px;
  position: absolute;
  width: 100%;
  height: 100%;

  &-Mounted {
    position: absolute;
    opacity: 1;
    top: 0;
    left: 0;
    transition: left 0.2s ease-in, opacity 0.2s ease-in;
    width: 100%;
    height: 100%;

    overflow-y: hidden;
    overflow-x: hidden;
  }
}

.Editor-Menu-Wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}
