@import '../../../../../../styles/variables';

.EditDialog {
  &-Background {
    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;

    z-index: 1001;

    background: $dialogBackground;
  }

  &-Content {
    position: fixed;
    width: 500px;
    min-height: 500px;

    z-index: 1002;

    background: $white;

    &-Icon {
      position: absolute;

      top: 1rem;
      right: 1rem;
    }

    &-Main {
      margin: 0 auto;
      padding: 2rem;
      height: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;

      &-Form {
        width: 100%;
        max-width: 400px;
        display: flex;
        flex-direction: column;

        gap: 0.25rem;

        &-Rights {
          padding-top: 0.5rem;
          display: flex;
          flex-direction: column;
          justify-content: left;
          margin-bottom: 1rem;
          gap: 0.25rem;
        }
      }
    }
  }
}
