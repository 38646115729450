@import 'src/styles/variables';

.Nav {
  margin-top: 3rem;
  .NavItem {
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 0.75rem;
    margin-bottom: 0.75rem;
    button {
      background: transparent;
      border: none;
      font-weight: bold;
      font-size: 16px;
      padding: 0;

      &:disabled {
        color: $disabledGrey;
        cursor: not-allowed;
      }
    }
  }
}
