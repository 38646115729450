@import '../../styles/variables';

// Variables:
$actionWidth: 1.5rem;

.Device-Tile-Small {
  display: flex;
  background: $white;
  padding: 0.5rem;
  margin-bottom: 0.5rem;

  &-Image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin-right: 0.5rem;
    flex-grow: 0;
    img {
      height: 65px;
      width: 65px;
      max-height: 125%;
      max-width: 125%;
      margin: -5px;
      padding: 3px;
    }
  }

  &-Description {
    position: relative;
    flex: 1;

    &-Title {
      font-size: 0.75rem;
      font-weight: bold;
      margin-bottom: 0.25rem;
      hyphens: auto;
      max-width: 90%;
    }

    &-Details {
      display: flex;
      max-width: 85%;
      &-Text {
        flex: 1;
        font-size: 0.65rem;
        hyphens: auto;
        max-width: 175px;
        overflow: hidden;
      }
    }

    &-Actions {
      position: absolute;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: row;

      &-Info {
        height: $actionWidth;
        width: $actionWidth;

        display: flex;
        justify-content: center;
        align-content: center;

        margin-right: 1rem;
        border: 1px solid $grey;
      }
      &-Add {
        height: $actionWidth;
        width: $actionWidth;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        background: $lightBlue;
        background: $lightBlue;
        border-color: transparent;
        padding: 3px;

        &-disabled {
          height: $actionWidth;
          width: $actionWidth;
          display: flex;
          justify-content: center;
          align-items: center;
          background: $grey;
          cursor: not-allowed;
          border-color: transparent;
          padding: 3px;
        }

        &-disabled-blue {
          height: $actionWidth;
          width: $actionWidth;
          display: flex;
          justify-content: center;
          align-items: center;
          background: $lightBlueDisabled;
          cursor: not-allowed;
          border-color: transparent;
          padding: 3px;
        }
      }
    }
  }
}

.Device-Tile-Full {
  background: $white;
  padding: 1rem 0.75rem 1.25rem 1.25rem;
  margin-bottom: 1rem;

  &-Header {
    display: flex;
    height: auto;
    img {
      max-height: 150px;
      max-width: 150px;
      width: 100%;
      margin-right: 0.75rem;
    }
    &-Title {
      font-weight: bold;
      font-size: 1.15rem;
    }
    &-Summary {
      color: $greyText;
      font-size: 0.85rem;
      margin-bottom: 0.75rem;
    }
    &-Numbers {
      font-weight: bold;
      font-size: 0.65rem;
      margin-bottom: 0.25rem;
    }
  }

  &-Details {
    margin-top: 1rem;
    justify-content: right;

    &-Content {
      max-width: 80%;
    }

    &-Content.expanded > &-Content-Collapsable {
      max-height: 500px;
      transition: max-height 0.3s ease-in;
      margin-bottom: 1.5rem;
    }

    &-Content-Collapsable {
      max-height: 0;
      overflow: hidden;

      &-Accordion {
        margin-top: 0.75rem;
        border-top: 1px solid $light;
        padding-top: 0.35rem;
        font-size: 0.65rem;
        color: $greyText;
        font-weight: bold;
        text-transform: uppercase;
      }

      &-Panel {
        color: $greyText;
        font-size: 0.65rem;
        font-weight: normal;
        text-transform: none;
      }

      &-Description {
        color: $greyText;
        font-size: 0.9rem;
        margin-bottom: 1rem;
      }
    }

    &-Controls {
      display: flex;
      justify-content: flex-end;

      &-ExpandBtn {
        background: transparent;
        border: none;
        text-transform: uppercase;
        font-weight: bold;
        margin-right: 0.5rem;
      }
    }
  }
}
