@import 'styles/_variables.scss';

.Search-Results {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.Search-Results::-webkit-scrollbar {
  width: 6px;
}

.Search-Results {
  scrollbar-width: thin;
  scrollbar-color: transparent $grey;
}
.Search-Results::-webkit-scrollbar-track {
  background: transparent;
}
.Search-Results::-webkit-scrollbar-thumb {
  background-color: $light;
  border-radius: 6px;
  border: 1px solid white;
}
