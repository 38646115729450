.FunctionalMenu {
  height: 100%;

  .SearchWrapper {
    height: 2.5rem;
  }
  .BackWrapper {
    margin-top: 1rem;
  }

  .Header {
    height: 120px;
  }

  .ScrollWrapper {
    height: 100%;

    .CustomScroll {
      height: 100%;
      max-height: calc(100% - 120px);
    }
  }
}
