// Variables:
@import '../../styles/variables';

.LoginPage {
  position: relative;
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100%;

  &-Suboptions {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &-Logo {
    position: absolute;
    top: 0.95rem;
    right: 1.33rem;
    height: 50px;
    width: 50px;
  }

  &-InputArea {
    align-items: center;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    text-align: center;
    height: 390px;
    width: 420px;

    &-Headline {
      font-size: 2.5rem;
      font-weight: 200;
      margin: 0 0 1rem 0;
    }
  }

  &-ErrorMessage {
    font-size: 0.8rem;
    color: red;
  }

  &-Form {
    margin-top: 1.5rem;
    height: 100%;
    width: 100%;
  }

  &-ResetForm {
    height: 100%;
    width: 100%;
    max-width: 350px;
    padding: 0 1rem 1rem 1rem;
    text-align: center;
  }

  &-InputWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  &-Text {
    max-width: 50%;

    color: $light;
    font-size: 0.8rem;
    text-align: right;
    font-weight: bold;

    cursor: pointer;
    transition: all 0.15s;

    &:hover {
      color: $medium;
    }
  }
}

.PasswordDialog-Background {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;

  z-index: 899;

  background: $dialogBackground;
}

.PasswordDialog-Content {
  text-align: center;
  position: fixed;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%); */

  z-index: 900;

  background: $white;

  height: 450px;
  width: 480px;

  margin: 0 auto;
  padding: 0 1rem 1rem 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  &-Icon {
    margin-bottom: 1rem;
  }
  &-Headline {
    font-size: 2.25rem;
    font-weight: 200;
    margin-bottom: 1rem;
  }
  &-Question {
    margin-bottom: 4rem;
  }
}

.PasswordHint {
  font-size: 0.75rem;
  margin: 0 auto;
  height: 2.5rem;
}
