@import 'styles/_variables.scss';

.Context-Menu {
  display: flex;
  margin-bottom: 2rem;
  width: 280px;

  background: $extraLight;
  z-index: 9000;

  &-NoMargin {
    display: flex;
    margin-bottom: 0rem;
    width: 280px;
  }

  &-Border {
    border-bottom: 2px solid $light;
    width: 280px;
  }
  &-Border-NoMargin {
    display: flex;
    padding-bottom: 0.75rem;
    margin-bottom: 0.75rem;
    border-bottom: 2px solid $light;
    width: 280px;
  }
}

.Submenu-Category {
  font-weight: bold;
  /*   font-size: 0.85rem;
 */

  cursor: pointer;
  border-bottom: 1px solid $light;
  padding-bottom: 0.75rem;
  margin-bottom: 0.75rem;

  &-Disabled {
    font-weight: bold;
    /*   font-size: 0.85rem;
 */
    cursor: pointer;
    border-bottom: 1px solid $light;
    padding-bottom: 0.75rem;
    margin-bottom: 0.75rem;
    cursor: not-allowed;
    color: $grey;
  }

  &-NoBorder {
    font-weight: bold;
    cursor: pointer;
    padding-bottom: 0.75rem;
    margin-bottom: 0.75rem;
  }
}

.Submenu-Headline {
  font-weight: bold;
  font-size: 0.85rem;
  margin-bottom: 1.5rem;
}

.Submenu-Attribute {
  display: flex;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid $light;
}

.Submenu-Input {
  border: none;
  border-radius: 0;
  background: transparent;
  border-bottom: 1px solid $medium;
  font-size: 1rem;
  padding: 0.25rem 0rem 0.25rem 0.5rem;
  width: 23%;
  text-align: center;
  margin-right: 0.25rem;

  &:focus {
    outline: none;
    border-bottom: 1px solid $lightBlue;
  }
}

.Submenu-Section {
  border-bottom: 2px solid $medium;
}

.Submenu-Label {
  font-weight: bold;
  font-size: 0.75rem;
  width: 100%;
}

.Submenu-AddButton {
  width: 66%;
  min-width: 205px;
}

.Submenu-Variant-View {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  background: white;
  padding: 0.75rem 0.75rem;

  &-Preview {
    display: flex;
    flex-direction: row;
    margin-right: 1rem;
  }

  &-TextWrapper {
    flex: 1;
  }

  &-Title {
    font-weight: bold;
    font-size: 0.75rem;
    margin-bottom: 0.25rem;
    margin-top: 0;
  }
  &-Series {
    font-weight: bold;
    font-size: 0.6rem;
    margin: 0;
  }
}

.Functional-View-ContextMenu {
  display: flex;
  flex: 1;
  &-Series {
    margin: 0 3rem;
    font-size: 0.65rem;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    text-align: center;
    flex: 1;
  }
}

.Functional-View-Switch-Area {
  border-top: 1px solid $light;
  margin-top: 0.5rem;
}

.Functional-Menu-Section {
  padding-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  /*   border-bottom: 2px solid $medium;
 */
  &-Title {
    font-weight: bold;
    font-size: 0.85rem;
    cursor: pointer;
  }
}

.Functional-Menu-Subsection {
  padding-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  border-bottom: 2px solid $light;
}

.Functional-Menu-Subsection-Title {
  font-weight: bold;
  font-size: 0.85rem;
  cursor: pointer;
}
