// Variables:
@import '../../styles/variables';

.Home {
  width: 100%;
  height: 100%;
  background: $background;

  display: flex;
  flex-direction: column;
  align-items: center;

  overflow: hidden !important;

  &-Heading {
    position: absolute;
    display: flex;
    width: 400px;
    height: 150px;
    left: 50%;
    transform: translate(-50%);
    overflow: hidden;
    padding-top: 3rem;

    &-Searchbar {
      display: flex;
      width: 800px;

      &-Inactive {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 400px;
        height: 50px;
      }

      &-Active {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 400px;
        border-bottom: 1px solid white;
        height: 50px;

        &-Textfield {
          flex: 1;
          background: transparent;
          border: none;
          color: white;
          text-align: center;
          font-size: 36px;
          padding: 0.5rem 1rem;
          font-weight: 200;
          max-width: calc(100% - 1.5rem);

          &:focus {
            outline: none;
          }

          &::placeholder {
            color: $grey;
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}

.discount-label {
  font-weight: bold;
  font-size: 0.75rem;
  width: 100%;
}

.discount-input {
  border: none;
  border-radius: 0;
  background: transparent;
  border-bottom: 1px solid $medium;
  font-size: 1rem;
  padding: 0.25rem 0rem 0.25rem 0.5rem;
  width: 23%;
  text-align: center;
  margin-right: 0.25rem;

  &:focus {
    outline: none;
    border-bottom: 1px solid $lightBlue;
  }
}
