// Variables:
@import '../../styles/variables';
$generalHeight: 35px;
$shadow: 0 2px 10px 0 rgba(133, 133, 133, 0.15);

$border: 1px solid rgba(0, 0, 0, 0.1);

// ===================================================================
.Control {
  position: absolute;
  left: 24px;
  bottom: 1rem;
  z-index: 15;

  height: $generalHeight;
  width: calc(100% - 48px);

  display: flex;
  align-items: center;
  justify-content: space-between;

  pointer-events: none;

  @media screen and (min-width: 760px) {
    bottom: 2rem;
  }

  /* Zoom Controls */
  &-Zoom {
    pointer-events: all;
    height: 100%;
    width: 117px;

    border-radius: 20px;
    background: $white;

    box-shadow: $shadow;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    &-Middle {
      height: 100%;

      padding: 0 0.33rem;

      border: none;
      border-left: $border;
      border-right: $border;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  /* ViewMode Controls */
  &-ViewMode {
    pointer-events: all;
    position: relative;

    height: 100%;
    width: 145px;

    border-radius: 20px;
    background: white;

    box-shadow: $shadow;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &-Item {
      height: 80%;
      width: 48%;

      border-radius: 20px;

      display: flex;
      justify-content: center;
      align-items: center;

      color: $medium;
      font-size: 0.6rem;
      font-weight: bold;

      transition: all 0.25s ease;
      cursor: pointer;
    }

    &-Slider {
      position: absolute;
      top: 10%;
      z-index: 10;

      height: 80%;
      width: 48%;

      border-radius: 20px;
      background: $lightBlue;

      display: flex;
      justify-content: center;
      align-items: center;

      color: $white;
      font-size: 0.6rem;
      font-weight: bold;

      cursor: pointer;
    }
  }

  /* General Controls */
  &-Generals {
    pointer-events: all;
    position: relative;

    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    &-Item {
      height: $generalHeight;
      width: $generalHeight;

      margin-left: 1rem;

      border-radius: 9999px;
      background: $white;

      box-shadow: $shadow;

      display: flex;
      justify-content: center;
      align-items: center;

      &-NoMargin {
        height: $generalHeight;
        width: $generalHeight;

        border-radius: 1rem;
        background: $white;

        box-shadow: $shadow;

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
