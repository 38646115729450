@import 'styles/_variables.scss';

.Selectbox {
  display: block;
  font-size: 0.75rem;
  font-family: sans-serif;
  font-weight: bold;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid $grey;
  border-radius: 0em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  /* TODO: replace icon */
  background-image: url('../../assets/svg/expand-button.svg');
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.Selectbox::-ms-expand {
  display: none;
}
.Selectbox:hover {
  border-color: #888;
}
.Selectbox:focus {
  border-color: #aaa;

  color: #222;
  outline: none;
}
.Selectbox option {
  font-weight: normal;
}

.SelectboxSecond {
  color: $lightBlue;
  display: block;
  font-size: 0.85rem;
  font-family: sans-serif;
  font-weight: bold;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: none;
  border-radius: 0em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  /* TODO: replace icon */
  background-image: url('../../assets/svg/expand-button.svg');
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.SelectboxSecond::-ms-expand {
  display: none;
}
/* .SelectboxSecond:hover {
  border-color: #888;
} */
.SelectboxSecond:focus {
  border-color: #aaa;
  outline: none;
}
.SelectboxSecond option {
  font-weight: normal;
}

.Selectbox-label {
  font-size: 0.83rem;
  font-weight: bold;
}
