// Variables:
@import '../../styles/variables';

.SettingsDialog-Background {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;

  z-index: 899;

  background: $dialogBackground;
}

.SettingsDialog-Content {
  position: fixed;

  width: 755px;
  height: 580px;

  z-index: 900;

  background: $white;

  &-Icon {
    position: absolute;

    top: 1rem;
    right: 1rem;
  }

  &-Main {
    margin: 0 auto;
    padding: 1rem 0 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    &-Inputs {
      text-align: left;
    }
  }
}
